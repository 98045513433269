function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import { trackEvent } from "/services/bigbrain-service";
import { getCookie } from "/services/cookies-service";
import { enterV13TestIfNeeded } from "services/ab-tests/monetization-ab-tests-service";
import { BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
import { TYPEFORM_CONTACT_SALES_FORM_CLOSED, TYPEFORM_CONTACT_SALES_FORM_LOADED, TYPEFORM_CONTACT_SALES_FORM_SUBMITTED, TYPEFORM_CONTACT_SALES_QUESTION_CHANGED } from "constants/bigbrain-event-types";
import { leadSourceDefaultValue } from "client/services/hubspot-service/hubspot-constants";
import { getEnrichmentEntriesForSales } from "services/sales-enrichment/sales-enrichment-service";
import { getPricingVersionForSales } from "services/pricing/pricing-model/services";
import { getTypeformContactSalesData } from "services/typeform-form-service";
import { isExistingAccount } from "services/monday-platform/existing-accounts-service";
export const TYPEFORM_DESKTOP_DEVICE_TYPE = "Desktop";
export const TYPEFORM_MOBILE_DEVICE_TYPE = "Mobile";
// ** TYPEFORM PARAMS **
const TYPEFORM_CONTACT_SALES_VISITOR_ID_PARAM = "visitor_id";
const TYPEFORM_CONTACT_SALES_COUNTRY_PARAM = "country";
const TYPEFORM_CONTACT_SALES_REGION_PARAM = "region";
const TYPEFORM_CONTACT_SALES_KEEP_SESSION_PARAM = "keepSession";
const TYPEFORM_CONTACT_SALES_DEVICE_PARAM = "Device";
const TYPEFORM_CONTACT_SALES_LEAD_SOURCE_PARAM = "lead_source";
const TYPEFORM_CONTACT_SALES_PRICING_VERSION = "website_pricing_version";
const TYPEFORM_FORM_TYPE_PARAM = "form_type_typeform";
const TYPEFORM_HUBSPOT_UTK_PARAM = "hubspot_utk";
const TYPEFORM_HUBSPOT_PAGE_URL_PARAM = "hubspot_page_url";
const TYPEFORM_HUBSPOT_PAGE_NAME_PARAM = "hubspot_page_name";
// *****************************
// ** SALES GEOGRAPHIC REGIONS **
export const EMEA_GEO_REGION = "EMEA";
export const AUSTRALIA_NEW_ZEALAND_GEO_REGION = "ANZ";
export const AMERICA_GEO_REGION = "AMERICA";
export const getContactSalesRegionByCountry = (countryCode)=>{
    const typeformData = getTypeformContactSalesData({
        country: countryCode
    });
    return typeformData === null || typeformData === void 0 ? void 0 : typeformData.region;
};
export const isTypeformDisabledForRegion = (countryCode)=>{
    // const typeformData = getTypeformContactSalesData({ country: countryCode });
    // disable for EMEA - "Iron Swords"
    // if (typeformData?.region === EMEA_GEO_REGION) return true;
    return false;
};
export const isTypeformFullyReleasedForCountry = (countryCode)=>{
    var _typeformData_excludeCountries;
    const typeformData = getTypeformContactSalesData({
        country: countryCode
    });
    if (typeformData === null || typeformData === void 0 ? void 0 : (_typeformData_excludeCountries = typeformData.excludeCountries) === null || _typeformData_excludeCountries === void 0 ? void 0 : _typeformData_excludeCountries.includes(countryCode)) return false;
    return true; // Fully released to all traffic except specific exclusions
};
export const getTypeformImagesPrefetchByCountry = (countryCode, getNewVariantForTest)=>{
    const typeformData = getTypeformContactSalesData({
        country: countryCode,
        getNewVariantForTest
    });
    return (typeformData === null || typeformData === void 0 ? void 0 : typeformData.prefetchImages) || [];
};
export const openContactSalesTypeform = ({ country, deviceType, isTest = false, getNewVariantForTest, pricingData, onCloseCallback, onSubmitCallback, onReadyCallback })=>{
    const typeformData = getTypeformContactSalesData({
        country,
        isTest,
        getNewVariantForTest
    });
    const { formId, region, formType } = typeformData;
    // fire AB test
    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID) || "";
    const pricingVersion = getPricingVersionForSales(pricingData);
    const enrichmentData = getEnrichmentEntriesForSales();
    const hidden = _object_spread_props(_object_spread({}, enrichmentData.reduce((data, item)=>(data[item.name] = item.value, data), {})), {
        [TYPEFORM_CONTACT_SALES_REGION_PARAM]: region,
        [TYPEFORM_CONTACT_SALES_COUNTRY_PARAM]: country,
        [TYPEFORM_CONTACT_SALES_KEEP_SESSION_PARAM]: "true",
        [TYPEFORM_CONTACT_SALES_VISITOR_ID_PARAM]: visitorId,
        [TYPEFORM_CONTACT_SALES_DEVICE_PARAM]: deviceType,
        [TYPEFORM_CONTACT_SALES_LEAD_SOURCE_PARAM]: leadSourceDefaultValue,
        [TYPEFORM_CONTACT_SALES_PRICING_VERSION]: pricingVersion,
        [TYPEFORM_FORM_TYPE_PARAM]: formType,
        [TYPEFORM_HUBSPOT_UTK_PARAM]: getCookie("hubspotutk"),
        [TYPEFORM_HUBSPOT_PAGE_URL_PARAM]: window.location.href,
        [TYPEFORM_HUBSPOT_PAGE_NAME_PARAM]: document.title
    });
    const { toggle } = createPopup(formId, {
        onQuestionChanged: (data)=>{
            trackEvent(TYPEFORM_CONTACT_SALES_QUESTION_CHANGED, {
                kind: data === null || data === void 0 ? void 0 : data.ref,
                info1: formId,
                info2: country,
                info3: isExistingAccount()
            });
        },
        onSubmit: (data)=>{
            trackEvent(TYPEFORM_CONTACT_SALES_FORM_SUBMITTED, {
                kind: data === null || data === void 0 ? void 0 : data.responseId,
                info1: formId,
                info2: country,
                info3: isExistingAccount()
            });
            if (pricingData) {
                const { abTests, cookies, forcePricing, isV13PricingVersion } = pricingData;
                enterV13TestIfNeeded(abTests, cookies, forcePricing, isV13PricingVersion);
            }
            onSubmitCallback === null || onSubmitCallback === void 0 ? void 0 : onSubmitCallback();
        },
        onReady: ()=>{
            trackEvent(TYPEFORM_CONTACT_SALES_FORM_LOADED, {
                info1: formId,
                info2: country,
                info3: isExistingAccount()
            });
            onReadyCallback === null || onReadyCallback === void 0 ? void 0 : onReadyCallback();
        },
        onClose: ()=>{
            trackEvent(TYPEFORM_CONTACT_SALES_FORM_CLOSED, {
                info1: formId,
                info2: country,
                info3: isExistingAccount()
            });
            onCloseCallback === null || onCloseCallback === void 0 ? void 0 : onCloseCallback();
        },
        hidden
    });
    toggle();
};
