const { MAIN_COUNTRY_CODES } = require("../services/countries/countries-service");
// ** FORM IDS **
const TYPEFORM_CONTACT_SALES_EMEA_SALES_AND_PARTNERS_FORM_ID = "Rb9pva6n";
const TYPEFORM_CONTACT_SALES_EMEA_SALES_ONLY_FORM_ID = "PlrwsC4F";
const TYPEFORM_CONTACT_SALES_EMEA_GERMAN_FORM_ID = "onptOGWz";
const TYPEFORM_CONTACT_SALES_FRENCH_FORM_ID = "B91EZP5w";
const TYPEFORM_CONTACT_SALES_PORTUGEESE_NO_SDR_FORM_ID = "fcCPz0Z1";
const TYPEFORM_CONTACT_SALES_SPANISH_NO_SDR_FORM_ID = "MCW2vdfv";
const TYPEFORM_CONTACT_SALES_ANZ_REGION_FORM_ID = "WYNld7NX";
const TYPEFORM_CONTACT_SALES_AMERICA_REGION_FORM_ID = "Fl3WSnnm";
const TYPEFORM_CONTACT_SALES_NO_SDR_FORM_ID = "idXP35wo";
// used for AB tests only
const TYPEFORM_CONTACT_SALES_AMERICA_REGION_TEST_VARIANT_FORM_ID = "xp0et7IG";
// used for testing only
const TEST_TYPEFORM_CONTACT_SALES_FORM_ID = "Qi8VILCS";
// ** SALES GEOGRAPHIC REGIONS **
const EMEA_GEO_REGION = "EMEA";
const AUSTRALIA_NEW_ZEALAND_GEO_REGION = "ANZ";
const AMERICA_GEO_REGION = "AMERICA";
const LATAM_GEO_REGION = "LATAM";
const REST_OF_THE_WORLD_GEO_REGION = "REST_OF_THE_WORLD";
const TEST_GEO_REGION = "TEST";
// *****************************
// **** FORM REGION TYPES: *******
// EMEA
const EMEA_SALES_AND_PARTNERS_REGION_TYPE = "EMEA_SALES_AND_PARTNERS";
const EMEA_SALES_ONLY_REGION_TYPE = "EMEA_SALES_ONLY";
const EMEA_PARTNERS_ONLY_REGION_TYPE = "EMEA_PARTNERS_ONLY";
const EMEA_FRENCH_SALES_AND_PARTNERS_REGION_TYPE = "EMEA_FRENCH_SALES_AND_PARTNERS";
const PORTUGAL_NO_SDR_TYPE = "PORTUGAL_NO_SDR";
const BRAZIL_NO_SDR_TYPE = "BRAZIL_NO_SDR";
const SPAIN_NO_SDR_TYPE = "SPAIN_NO_SDR";
const EMEA_GERMAN_SALES_AND_PARTNERS_REGION_TYPE = "EMEA_GERMAN_SALES_AND_PARTNERS_REGION";
const LATAM_SPANISH_REGIONS_NO_SDR_TYPE = "LATAM_SPANISH_NO_SDR";
// ANZ
const AUSTRALIA_NZ_SALES_ONLY_TYPE = "ANZ_SALES_ONLY";
// AMERICA
const AMERICA_SALES_ONLY_TYPE = "AMERICA_SALES_ONLY";
// DEFAULT
const DEFAULT_NO_SDR_TYPE = "DEFAULT_NO_SDR";
// TEST
const TEST_REGION_TYPE = "TEST_SALES_ONLY";
// *****************************
const CONTACT_SALES_REGIONS = {
    [EMEA_SALES_AND_PARTNERS_REGION_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.NETHERLANDS_ISO2,
            MAIN_COUNTRY_CODES.BELGIUM_ISO2,
            MAIN_COUNTRY_CODES.SWEDEN_ISO2,
            MAIN_COUNTRY_CODES.NORWAY_ISO2,
            MAIN_COUNTRY_CODES.DENMARK_ISO2,
            MAIN_COUNTRY_CODES.LUXEMBOURG_ISO2,
            MAIN_COUNTRY_CODES.ICELAND_ISO2,
            MAIN_COUNTRY_CODES.FINLAND_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_EMEA_SALES_AND_PARTNERS_FORM_ID,
        formType: EMEA_SALES_AND_PARTNERS_REGION_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/Aw2xNHE6PiND/image/default",
            "https://images.typeform.com/images/Aw2xNHE6PiND/image/default-firstframe.png"
        ]
    },
    [EMEA_SALES_ONLY_REGION_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.UNITED_KINGDOM_ISO2,
            MAIN_COUNTRY_CODES.ISRAEL_ISO2,
            MAIN_COUNTRY_CODES.IRELAND_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_EMEA_SALES_ONLY_FORM_ID,
        formType: EMEA_SALES_ONLY_REGION_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/zQ2kQjnuBzW3/image/default",
            "https://images.typeform.com/images/zQ2kQjnuBzW3/image/default-firstframe.png"
        ]
    },
    [EMEA_GERMAN_SALES_AND_PARTNERS_REGION_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.GERMANY_ISO2,
            MAIN_COUNTRY_CODES.SWITZERLAND_ISO2,
            MAIN_COUNTRY_CODES.AUSTRIA_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_EMEA_GERMAN_FORM_ID,
        formType: EMEA_GERMAN_SALES_AND_PARTNERS_REGION_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/Jue6uWpkSzyq/image/default",
            "https://images.typeform.com/images/Jue6uWpkSzyq/image/default-firstframe.png"
        ]
    },
    [EMEA_PARTNERS_ONLY_REGION_TYPE]: {
        // Can be removed and merged with DEFAULT_NO_SDR_TYPE once we release to entire world
        countries: [
            MAIN_COUNTRY_CODES.ARMENIA_ISO2,
            MAIN_COUNTRY_CODES.AZERBAIJAN_ISO2,
            MAIN_COUNTRY_CODES.BAHRAIN_ISO2,
            MAIN_COUNTRY_CODES.BELARUS_ISO2,
            MAIN_COUNTRY_CODES.BULGARIA_ISO2,
            MAIN_COUNTRY_CODES.CROATIA_ISO2,
            MAIN_COUNTRY_CODES.CYPRUS_ISO2,
            MAIN_COUNTRY_CODES.CZECHIA_ISO2,
            MAIN_COUNTRY_CODES.EGYPT_ISO2,
            MAIN_COUNTRY_CODES.ESTONIA_ISO2,
            MAIN_COUNTRY_CODES.GEORGIA_ISO2,
            MAIN_COUNTRY_CODES.GREECE_ISO2,
            MAIN_COUNTRY_CODES.HUNGARY_ISO2,
            MAIN_COUNTRY_CODES.ITALY_ISO2,
            MAIN_COUNTRY_CODES.KAZAKHSTAN_ISO2,
            MAIN_COUNTRY_CODES.KENYA_ISO2,
            MAIN_COUNTRY_CODES.KUWAIT_ISO2,
            MAIN_COUNTRY_CODES.LATVIA_ISO2,
            MAIN_COUNTRY_CODES.LITHUANIA_ISO2,
            MAIN_COUNTRY_CODES.MAURITIUS_ISO2,
            MAIN_COUNTRY_CODES.MOZAMBIQUE_ISO2,
            MAIN_COUNTRY_CODES.NIGERIA_ISO2,
            MAIN_COUNTRY_CODES.OMAN_ISO2,
            MAIN_COUNTRY_CODES.POLAND_ISO2,
            MAIN_COUNTRY_CODES.QATAR_ISO2,
            MAIN_COUNTRY_CODES.ROMANIA_ISO2,
            MAIN_COUNTRY_CODES.RUSSIA_ISO2,
            MAIN_COUNTRY_CODES.RWANDA_ISO2,
            MAIN_COUNTRY_CODES.SAUDI_ARABIA_ISO2,
            MAIN_COUNTRY_CODES.SLOVAKIA_ISO2,
            MAIN_COUNTRY_CODES.SLOVENIA_ISO2,
            MAIN_COUNTRY_CODES.SOUTH_AFRICA_ISO2,
            MAIN_COUNTRY_CODES.SOUTH_SUDAN_ISO2,
            MAIN_COUNTRY_CODES.TANZANIA_ISO2,
            MAIN_COUNTRY_CODES.TURKEY_ISO2,
            MAIN_COUNTRY_CODES.UGANDA_ISO2,
            MAIN_COUNTRY_CODES.UKRAINE_ISO2,
            MAIN_COUNTRY_CODES.UNITED_ARAB_EMIRATES_ISO2,
            MAIN_COUNTRY_CODES.UZBEKISTAN_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_NO_SDR_FORM_ID,
        formType: EMEA_PARTNERS_ONLY_REGION_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/2YTm6WS6wcLe/image/default",
            "https://images.typeform.com/images/2YTm6WS6wcLe/image/default-firstframe.png"
        ]
    },
    [EMEA_FRENCH_SALES_AND_PARTNERS_REGION_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.FRANCE_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_FRENCH_FORM_ID,
        formType: EMEA_FRENCH_SALES_AND_PARTNERS_REGION_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/rZDcCEwqLVtd/image/default",
            "https://images.typeform.com/images/rZDcCEwqLVtd/image/default-firstframe.png"
        ]
    },
    [AUSTRALIA_NZ_SALES_ONLY_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.AUSTRALIA_ISO2,
            MAIN_COUNTRY_CODES.NEW_ZEALAND_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_ANZ_REGION_FORM_ID,
        formType: AUSTRALIA_NZ_SALES_ONLY_TYPE,
        region: AUSTRALIA_NEW_ZEALAND_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/2wA8X5swymX2/image/default",
            "https://images.typeform.com/images/2wA8X5swymX2/image/default-firstframe.png"
        ]
    },
    [AMERICA_SALES_ONLY_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.UNITED_STATES_ISO2,
            MAIN_COUNTRY_CODES.CANADA_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_AMERICA_REGION_FORM_ID,
        formType: AMERICA_SALES_ONLY_TYPE,
        region: AMERICA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/KCQAM5ifzaty/image/default",
            "https://images.typeform.com/images/KCQAM5ifzaty/image/default-firstframe.png"
        ],
        // Test variant
        variantFormId: TYPEFORM_CONTACT_SALES_AMERICA_REGION_TEST_VARIANT_FORM_ID,
        variantPrefetchImages: [
            "https://images.typeform.com/images/uJRuZPNTVcVz/image/default",
            "https://images.typeform.com/images/uJRuZPNTVcVz/image/default-firstframe.png"
        ]
    },
    [PORTUGAL_NO_SDR_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.PORTUGAL_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_PORTUGEESE_NO_SDR_FORM_ID,
        formType: PORTUGAL_NO_SDR_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/6NuLbnPVhJxy/image/default",
            "https://images.typeform.com/images/6NuLbnPVhJxy/image/default-firstframe.png"
        ]
    },
    [BRAZIL_NO_SDR_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.BRAZIL_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_PORTUGEESE_NO_SDR_FORM_ID,
        formType: BRAZIL_NO_SDR_TYPE,
        region: LATAM_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/6NuLbnPVhJxy/image/default",
            "https://images.typeform.com/images/6NuLbnPVhJxy/image/default-firstframe.png"
        ]
    },
    [SPAIN_NO_SDR_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.SPAIN_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_SPANISH_NO_SDR_FORM_ID,
        formType: SPAIN_NO_SDR_TYPE,
        region: EMEA_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/FV6CXnihkcXG/image/default",
            "https://images.typeform.com/images/FV6CXnihkcXG/image/default-firstframe.png"
        ]
    },
    [LATAM_SPANISH_REGIONS_NO_SDR_TYPE]: {
        countries: [
            MAIN_COUNTRY_CODES.MEXICO_ISO2,
            MAIN_COUNTRY_CODES.COSTA_RICA_ISO2,
            MAIN_COUNTRY_CODES.EL_SALVADOR_ISO2,
            MAIN_COUNTRY_CODES.GUATEMALA_ISO2,
            MAIN_COUNTRY_CODES.HONDURAS_ISO2,
            MAIN_COUNTRY_CODES.NICARAGUA_ISO2,
            MAIN_COUNTRY_CODES.PANAMA_ISO2,
            MAIN_COUNTRY_CODES.CUBA_ISO2,
            MAIN_COUNTRY_CODES.DOMINICAN_REPUBLIC_ISO2,
            MAIN_COUNTRY_CODES.PUERTO_RICO_ISO2,
            MAIN_COUNTRY_CODES.ARGENTINA_ISO2,
            MAIN_COUNTRY_CODES.BOLIVIA_ISO2,
            MAIN_COUNTRY_CODES.CHILE_ISO2,
            MAIN_COUNTRY_CODES.COLUMBIA_ISO2,
            MAIN_COUNTRY_CODES.ECUADOR_ISO2,
            MAIN_COUNTRY_CODES.PARAGUAY_ISO2,
            MAIN_COUNTRY_CODES.PERU_ISO2,
            MAIN_COUNTRY_CODES.URUGUAY_ISO2,
            MAIN_COUNTRY_CODES.VENEZUELA_ISO2,
            MAIN_COUNTRY_CODES.EQUATORIAL_GUINEA_ISO2
        ],
        formId: TYPEFORM_CONTACT_SALES_SPANISH_NO_SDR_FORM_ID,
        formType: LATAM_SPANISH_REGIONS_NO_SDR_TYPE,
        region: LATAM_GEO_REGION,
        prefetchImages: [
            "https://images.typeform.com/images/FV6CXnihkcXG/image/default",
            "https://images.typeform.com/images/FV6CXnihkcXG/image/default-firstframe.png"
        ]
    },
    [DEFAULT_NO_SDR_TYPE]: {
        countries: [],
        formId: TYPEFORM_CONTACT_SALES_NO_SDR_FORM_ID,
        formType: DEFAULT_NO_SDR_TYPE,
        region: REST_OF_THE_WORLD_GEO_REGION,
        excludeCountries: [
            MAIN_COUNTRY_CODES.JAPAN_ISO2
        ],
        prefetchImages: [
            "https://images.typeform.com/images/2YTm6WS6wcLe/image/default",
            "https://images.typeform.com/images/2YTm6WS6wcLe/image/default-firstframe.png"
        ]
    },
    [TEST_REGION_TYPE]: {
        countries: [],
        formId: TEST_TYPEFORM_CONTACT_SALES_FORM_ID,
        formType: TEST_REGION_TYPE,
        region: TEST_GEO_REGION,
        prefetchImages: []
    }
};
module.exports = {
    CONTACT_SALES_REGIONS
};
