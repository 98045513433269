import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MIN_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
import colors from "styles/colors";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
const bannerHeight = "72px";
const bannerTextLinHeight = "24px";
export const StyledBanner = styled.div`
  height: ${bannerHeight};
  display: flex;
  justify-content: center;
  position: relative;
  &.top-margin {
    margin-top: ${Constants.SECONDARY_HEADER_HEIGHT}px;
  }

  .banner-content {
    display: flex;
    width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;

    .image-wrapper {
      display: flex;
      flex-grow: 0;
      .banner-image {
        height: ${bannerHeight};
      }
    }

    &.with-sticky-images {
      width: auto;
      align-items: center;
      .banner-image {
        height: ${bannerTextLinHeight};
      }
    }

    .banner-text-and-cta {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .banner-text-and-link {
        display: flex;
        align-items: center;
        .banner-text {
          display: inline;
          font-size: 1rem;
          line-height: ${bannerTextLinHeight};
          text-align: center;
        }

        .banner-icon-wrapper {
          display: flex;
          margin-right: 8px;
          .picture-component {
            display: flex;
          }
        }
      }

      .banner-cta-wrapper {
        display: inline-block;
        margin-left: 24px;
        min-width: 190px;
        .ladda-button {
          padding: 12px 20px;
          background: ${colors.white};
          ${({ buttonTextColor })=>buttonTextColor && `color: ${colors[buttonTextColor]};`}
        }
      }
    }
  }
  .close-icon-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${colors["white"]};
      }
    }
  }

  &.hide-banner {
    display: none;
  }

  @media (max-width: ${MIN_HEADER_DESKTOP_MENU}px) {
    &.top-margin {
      margin-top: 0px;
    }
    .banner-content {
      width: initial;

      .image-wrapper {
        .banner-picture {
          display: flex;
          align-items: center;

          .banner-image {
            height: 50px;
          }
        }
      }
      .banner-text-and-cta {
        margin-left: 20px;
        margin-right: 35px;
        justify-content: center;
        flex-grow: 0;
        flex-direction: column;

        .banner-cta-wrapper {
          margin-left: 0;
          min-width: unset;
          align-self: start;

          .secondary-button {
            font-size: 0.8125rem;
          }
        }

        .banner-text-and-link {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 10px;

          .banner-text {
            font-size: 1rem;
            text-align: left;
            font-weight: 400;
          }

          .banner-link {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .banner-content {
      .banner-text-and-cta {
        .banner-text-and-link {
          display: inline-block;
          text-align: center;
          max-width: 274px;
          .banner-text {
            font-size: 0.8125rem;
          }
          .banner-link {
            font-size: 0.8125rem;
          }
          .banner-cta-wrapper {
            margin-left: 16px;
          }
        }
      }
    }
  }
`;
