import { shouldGetContactSalesTypeformEmeaTest, shouldGetContactSalesTypeformAnzTest } from "services/ab-tests/acquisition-ab-test-service";
import { EMEA_GEO_REGION, AUSTRALIA_NEW_ZEALAND_GEO_REGION, AMERICA_GEO_REGION, getContactSalesRegionByCountry, isTypeformFullyReleasedForCountry, isTypeformDisabledForRegion } from "constants/typeform-contact-sales";
const regionToTestMap = {
    [EMEA_GEO_REGION]: shouldGetContactSalesTypeformEmeaTest,
    [AUSTRALIA_NEW_ZEALAND_GEO_REGION]: shouldGetContactSalesTypeformAnzTest
};
export const getTestIsNewVariantByRegion = ({ region, abTests, sendBigBrainEvent })=>{
    const testByRegion = regionToTestMap[region] || (()=>false);
    return testByRegion(abTests, sendBigBrainEvent);
};
export const isEligibleToGetTypeform = (region)=>{
    return region === EMEA_GEO_REGION || region === AUSTRALIA_NEW_ZEALAND_GEO_REGION || region === AMERICA_GEO_REGION;
};
export const shouldGetTypeformExperience = ({ abTests, isTypeformDisabled, cloudfrontViewerCountry, testTypeformContactSales, forceTypeformContactSalesExperience, sendBigBrainEvent = false })=>{
    if (isTypeformDisabled) return false;
    if (forceTypeformContactSalesExperience) return true;
    if (isTypeformDisabledForRegion(cloudfrontViewerCountry)) return false;
    if (isTypeformFullyReleasedForCountry(cloudfrontViewerCountry)) return true;
    const region = getContactSalesRegionByCountry(cloudfrontViewerCountry);
    return isEligibleToGetTypeform(region) && (testTypeformContactSales || getTestIsNewVariantByRegion({
        region,
        abTests,
        sendBigBrainEvent
    }));
};
