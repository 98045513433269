function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const { CONTACT_SALES_REGIONS } = require("../constants/contact-sales-forms");
// DEFAULT
const DEFAULT_NO_SDR_TYPE = "DEFAULT_NO_SDR";
// TEST
const TEST_REGION_TYPE = "TEST_SALES_ONLY";
// *****************************
const overrideTypeformContactSalesWithVariantDataIfNeeded = ({ contactSalesData, getNewVariantForTest })=>{
    if (!getNewVariantForTest) return contactSalesData;
    const newProps = {};
    if (contactSalesData.variantFormId) {
        newProps.formId = contactSalesData.variantFormId;
    }
    if (contactSalesData.variantPrefetchImages) {
        newProps.prefetchImages = contactSalesData.prefetchImages;
    }
    return _object_spread({}, contactSalesData, newProps);
};
const getTypeformContactSalesData = ({ country, isTest, getNewVariantForTest = false })=>{
    // this function can not return null - must return a regionType from CONTACT_SALES_REGIONS
    if (isTest) {
        return CONTACT_SALES_REGIONS[TEST_REGION_TYPE];
    }
    const regions = Object.keys(CONTACT_SALES_REGIONS);
    const regionType = regions.find((region)=>{
        var _CONTACT_SALES_REGIONS_region;
        return (_CONTACT_SALES_REGIONS_region = CONTACT_SALES_REGIONS[region]) === null || _CONTACT_SALES_REGIONS_region === void 0 ? void 0 : _CONTACT_SALES_REGIONS_region.countries.includes(country);
    }) || DEFAULT_NO_SDR_TYPE;
    return overrideTypeformContactSalesWithVariantDataIfNeeded({
        contactSalesData: CONTACT_SALES_REGIONS[regionType],
        getNewVariantForTest
    });
};
module.exports = {
    getTypeformContactSalesData
};
